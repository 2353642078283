export class StringUtils 
{
    /**
     * Obtem as iniciais de uma string e devolve resultado em uppercase.
     * @param str string
     * @returns 
     */
    public static GetInitials(value)
    {
        if (!value) return '';

        if (typeof value === 'string' || value instanceof String) return value.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();

        return value;
    }

    /**
     * Recebe como param um string e coloca em uppercase a primeira letra de cada palavra existente na string.
     * @param value string
     * @returns 
     */
    public static UppercaseWords(value): string
    {
        if (typeof value === 'string' || value instanceof String) return value.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());

        return value;
    }

    /**
     * Recebe como param duas strings para comparar se o seu valor é igual.
     * A comparação vai fazer uso do metodo localeCompare com o sensitivity: 'base' como valor default.
     * @param valueA string
     * @param valueB string
     * @param sensitivity string "base" | "accent" | "case" | "variant
     * @returns 
     */
    public static AreValuesEqual(valueA: string, valueB: string, sensitivity: "base" | "accent" | "case" | "variant" = "base"): boolean
    {
        if (typeof valueA !== 'string' || typeof valueB !== 'string') return false;

        return valueA.localeCompare(valueB, undefined, { sensitivity: sensitivity }) === 0;
    }

    /**
     * Normaliza string removendo acentos ou outros chars especificos de linguagem.
     * @param value string
     * @returns 
     */
    public static Normalize(value): string
    {
        if (typeof value === 'string' || value instanceof String) return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        return value;
    }

    /**
     * Recebe uma string como parametro efectua trim() e remove white space duplicado.
     * @param value string to trim
     * @returns 
     */
    public static Trim(value):string
    {
        if (typeof value === 'string' || value instanceof String) return value.trim().replace(/\s+/g, ' ');

        return value;
    }

    /**
     * Recebe uma string como parametro e remove white todo o white space existente.
     * @param value string para remover todo o espaços
     * @returns 
     */
    public static RemoveWhiteSpace(value)
    {
        if (typeof value === 'string' || value instanceof String) return value.replace(/\s+/g, '');

        return value;
    }
    
    /**
     * pesquisa pela letra e devolve o valor do ATCUD
     * @param value 
     * @param letter 
     * @returns 
     */
    public static ATCUD(value, letter: string = ''):string
    {
        if ((typeof value === 'string' || value instanceof String) && letter) 
        {

            const props = value.split('*');

            console.log(props);            

            return props?.find(it=> it.match(letter+':'))?.split(':')[1];
        }

        return null;
    }

    
    /**
     * devolve lista do atcud por key value
     * @param value 
     * @returns 
     */
    public static ATCUDArray(value):any[]
    {
        if (typeof value === 'string') 
        {

            const props = value?.split('*');

            // console.log(props);            

            return props?.map(it=> {return {Key: it?.split(':')[0], Value: it?.split(':')[1]}});
        }

        return null;
    }


    /**
     * copia a string e retorna o resultado da operacao
     * @param text 
     * @returns 
     */
    public static ClipboardText(text:string = ''):boolean
    {
        if (!text) return false;

        try {
            
            navigator.clipboard.writeText(text);

            console.log("clipboard -> ", text);

            return true;
        
        } catch (error) {
            
            console.log(error);

            return false;
            
        }
    }

    /**
     * 
     * @param number 
     * @returns 
     */
    public static FormatToCreditCardNumber(number:string):string
    {
        return StringUtils.RemoveWhiteSpace(number).replace(/(.{4})/g, '$1 ').trim();
    }    
    
    /**
     * 
     * @param date 
     * @returns 
     */
    public static FormatATCUDDate(date:string):string
    {

        // Extract the year, month, and day from the string
        const year = StringUtils.RemoveWhiteSpace(date.substring(0, 4));
        const month = StringUtils.RemoveWhiteSpace(date.substring(4, 6));
        const day = StringUtils.RemoveWhiteSpace(date.substring(6, 8));

        // Format the date as yyyy-mm-dd
        return `${year}-${month}-${day}`;

    }
}

