<ion-input type="text" class="full outline" [disabled]="IsDisabled" [value]="FieldValue"
	(click)="OnPresentPopover($event)" readonly [placeholder]="Placeholder" />

<ion-popover #popover [isOpen]="IsOpen" (didDismiss)="IsOpen = false" class="select-search">
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-grid>
					<ion-row>
						<ion-item class="full">
							<ion-searchbar #search class="outline ion-no-margin"></ion-searchbar>
						</ion-item>
					</ion-row>
				</ion-grid>
			</ion-toolbar>
		</ion-header>

		<ion-content [scrollY]="false" class="ion-no-padding">
			<cdk-virtual-scroll-viewport class="ion-content-scroll-host popover" itemSize="48" minBufferPx="750"
				maxBufferPx="1360">

				<ion-grid class="ion-padding-horizontal">
					<ion-row
						*cdkVirtualFor="let item of Data | FilterByFields: (search?.value||''); let itemBounds = bounds; let i = index; trackBy: trackByFn">

						<ion-item class="full" style="margin: 0; height: 48px; overflow: hidden;">

							<ion-checkbox [value]="item" slot="start" [checked]="item?.Checked"
								(ionChange)="OnChangeValue($event, item)"></ion-checkbox>

							<ion-label>{{ '[' + (item?.Code|| '--' ) + '] ' +
								(item?.Description||'--')}}</ion-label>
						</ion-item>

					</ion-row>
					<no-items [description]="'sem resultados'"
						*ngIf="(Data | FilterByFields: (search?.value||''))?.length==0"></no-items>
				</ion-grid>
			</cdk-virtual-scroll-viewport>
		</ion-content>
	</ng-template>
</ion-popover>