import { ProjectService } from '@services/project.service';

import { EnumAppMenu } from '@models/AppMenus';
import { Subscription } from 'rxjs';
import { SessionService } from '@services/session.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUserInfoModel } from '@models/User';

@Component({
	selector: 'side-menu-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class SideMenuHeaderComponent implements OnInit, OnDestroy {

	public User: IUserInfoModel;

	public ProjectLogo: string = "";

	private sub: Subscription;

	public NotificationPath: string = EnumAppMenu.Notifications;

	public HomePath: string = EnumAppMenu.Dashboard;

	constructor(private SessionService: SessionService,
		private ProjectService: ProjectService) { }

	ngOnInit() {

		this.sub = this.SessionService.Session.subscribe(session => {

			this.User = session?.UserData;

			this.ProjectLogo = this.ProjectService.GetSettings()?.Project?.Logos?.Header || '';

			// console.log(this.User);


		})

	}

	ngOnDestroy(): void {
		if (this.sub)
			this.sub.unsubscribe();
	}


}
