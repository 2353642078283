import { Component, Input, OnInit } from '@angular/core';
import { StringUtils } from '@utils/StringUtils';

@Component({
	selector: 'wallet-badge',
	templateUrl: './wallet-badge.component.html',
	styleUrls: ['./wallet-badge.component.scss'],
})
export class WalletBadgeComponent implements OnInit {

	@Input('wallet') Wallet: string = '';
	@Input('card') CardDetails: any;
	@Input('showToast') showToast: Function;

	constructor() { }

	ngOnInit() { }

	/**
	 * 
	 * @param ev 
	 * @param text 
	 */
	OnCopy(ev: any, text) {

		const clip = StringUtils.ClipboardText(text);

		if (this.showToast && clip)
			this.showToast($localize`:@@toast.clipboard:Número copiado`)

	}

}
