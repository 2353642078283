import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';

@Component({
    selector: 'app-select-merchant-campaign',
    templateUrl: './select-merchant-campaign.component.html',
    styleUrls: ['./select-merchant-campaign.component.scss'],
})
export class SelectMerchantCampaignModalComponent implements OnInit {

    public Title: string = "";
    public Data: { Id: string, Name: string, Checked: boolean }[] = [];
    private copyData: { Id: string, Name: string, Checked: boolean }[] = [];
    private saveSelection: Function;
    private deleteSelection: Function;
    private showAlert: Function;
    private showToast: Function;

    public AllChecked: boolean = false;


    constructor(
        private NavParams: NavParams,
        private ModalController: ModalController) {

        // this.Title = this.NavParams.get('title');
        this.Data = this.NavParams.get('data');
        this.copyData = Object.assign([], JSON.parse(JSON.stringify(this.Data)));
        this.saveSelection = this.NavParams.get('save');
        this.deleteSelection = this.NavParams.get('delete');
        this.showAlert = this.NavParams.get('showAlert');
        this.showToast = this.NavParams.get('showToast');


        console.log(this.Data);

    }


    ngOnInit() { }

    async OnSaveSelection(ev: any) {

        ev?.preventDefault();
        ev?.stopPropagation();


        if (JSON.stringify(this.copyData) == JSON.stringify(this.Data)) {
            return this.showToast("Não existem alterações");
        }


        const { role } = await this.showAlert();

        if (role != EnumGenericRoleActionAlerts.Confirm) return;


        let success: boolean = false;

        const allreadySaved = this.copyData?.filter(it => it.Checked)?.map(it => it.Id);

        if (this.saveSelection) {

            const data = this.Data.filter(it => it.Checked && (!allreadySaved.some(i => it.Id == i)))?.map(it => it.Id) || [];
            if (data?.length > 0) {
                const r = await this.saveSelection(data);
                console.log(r);
                success = r?.IsSuccessStatus;

            }

        }

        if (this.deleteSelection) {
            const data = this.Data.filter(it => !it.Checked && (allreadySaved.some(i => it.Id == i)))?.map(it => it.Id) || [];
            if (data?.length > 0) {
                const r = await this.deleteSelection(data);
                console.log(r);
                success = r?.IsSuccessStatus;
            }

        }

        if (success) {
            this.showToast("Operação realizada com sucesso.");
            this.ModalController.dismiss(this.Data.filter(it => it.Checked), EnumGenericRoleActionAlerts.Save);
        }
    }

    OnChangeValue(ev: any, item, all: boolean = false) {

        console.log(ev);
        ev?.preventDefault();
        ev?.stopPropagation();

        const isChecked = all ? !this.AllChecked : ev?.detail?.checked;

        if (item && item.Checked != isChecked) {
            const it = this.Data?.find(it => item?.Id == it.Id)
            it['Checked'] = isChecked;

            this.AllChecked = this.Data.filter(it => it.Checked == false).length == 0;
        }
        else if (all) {

            try {
                this.Data.forEach(element => {
                    element['Checked'] = isChecked;
                });
            } catch (error) {
                console.log(error);

            }

        }
        else {

            this.AllChecked = this.Data.filter(it => it.Checked).length == this.Data.length;
        }

    }

    Close() {

        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
    }

}
