import { IContactModel, IEntityModel, IKeyValue, ILocationModel } from "./Common";

export interface IUserInfoModel {
	Name: string,
	BirthDate: string,
	VatNumber: string,
	Email: string,
	EmailVerified: boolean,
	PhoneVerified: boolean,
	Username: string,
	Contact: IUserInfoContactModel,
	Image: IUserInfoImageModel,
	FirstName: string,
	LastName: string,
	IdRole?: number,
	Template?: { Id: string, Name: string };
}

interface IUserInfoContactModel {
	Id: number,
	CreateDate: string,
	CreateUser: number,
	UpdateDate: string,
	UpdateUser: number,
	IsActive: boolean,
	RegistDate: string,
	RegistUser: number,
	Phone: string,
	MobilePhone: string,
	Email: string,
	LabelPhoneList: string
}

interface IUserInfoImageModel {
	Id: number,
	CreateDate: string,
	CreateUser: number,
	UpdateDate: string,
	UpdateUser: number,
	IsActive: boolean,
	RegistDate: string,
	RegistUser: number,
	PhysicalName: string,
	OriginalName: string,
	Type: string,
	Path: string,
	Code: string,
	ByteData: string,
	UploadFileData: string,
	Base64Data: string,
	AvailableForCDN: boolean
}

export class UserInfoModel implements IUserInfoModel {
	Name: string;
	BirthDate: string;
	VatNumber: string;
	Email: string;
	EmailVerified: boolean;
	PhoneVerified: boolean;
	Username: string;
	Contact: IUserInfoContactModel;
	Image: IUserInfoImageModel;
	FirstName: string;
	LastName: string;
	IdRole?: number;
	Template?: { Id: string, Name: string };

	constructor(data: Partial<IUserInfoModel> = {}) {

		this.Name = data?.Name || '';
		this.BirthDate = data?.BirthDate || '';
		this.VatNumber = data?.VatNumber || '';
		this.Email = data?.Email || '';
		this.EmailVerified = data?.EmailVerified || null;
		this.PhoneVerified = data?.PhoneVerified || null;
		this.Username = data?.Username || '';
		this.Contact = data?.Contact || null;
		this.Image = data?.Image || null;
		this.FirstName = data?.FirstName || null;
		this.LastName = data?.LastName || null;
		this.IdRole = data?.IdRole || null;
		this.Template = data?.Template || null;

	}
}


export interface IUserModel {
	Id: string;
	IdUser: string;
	IdEntity: string;
	ExternalUser_Id?: null,
	ExternalUser_Name?: null,
	ExternalUser_Email?: null,
	ExternalUser_VatNumber?: null,
	Name: string;
	IdGender?: number,
	BirthDate: string;
	Age?: number;
	IdLocation?: string;
	IdContact?: string;
	IdImage?: null,
	IdentificationDocumentNumber?: string;
	IdentificationDocumentValidityDate?: string;
	IdentificationDocumentEmissionDate?: string;
	IdDocumentType?: string;
	VatNumber?: string;
	BankAccountNumber?: string;
	IdEmergencyData?: string;
	Entity: IEntityModel,
	Gender: IKeyValue,
	Location: ILocationModel,
	Contact: IContactModel,
	Image?: any,
	EmergencyData?: any,
	DocumentType?: any,
	PermissionList?: any[],
	IsExternal: boolean,
	LabelSelectionOption?: string;
	StringData?: string;
	LabelEntity?: string;
	IdTemplate: string;
	Username: string;
	EmailVerified: boolean,
	CreateDate?: string;
}
