import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { PipesModule } from '../pipes/pipes.module';
import { FooterPageComponent } from './footer-page/footer-page.component';

import { LoadingTableComponent } from './loading-table/loading-table.component';
import { FormErrorsComponent } from './forms/form-errors/form-errors.component';
import { InputBrowseComponent } from './input-browse/input-browse.component';
import { GoogleMapContainerComponent } from './google-map-container/google-map-container.component';

import { PasswordToggleComponent } from './password-toggle/password-toggle.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { NoItemsComponent } from './no-items/no-items.component';

import { PaginationComponent } from './pagination/pagination.component';
import { HeaderPageComponent, PopoverHeaderComponent } from './header-page/header-page.component';
import { DirectivesModule } from './../directives/directives.module';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { TextEditorQuillComponent } from './text-editor-quill/text-editor-quill.component';
import { SelectEditionComponent } from './select-edition/select-edition.component';
import { SidemMenuComponent } from './side-menu/side-menu.component';
import { SideMenuHeaderComponent } from './side-menu/header/header.component';
import { SideMenuContentComponent } from './side-menu/content/content.component';
import { AgeGroupsComponent } from './forms/age-groups/age-groups.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { DashboardComponent } from './cards/dashboard/dashboard.component';
import { SimpleChartComponent } from './charts/simple/simple.component';
import { MenuConfigurationsComponent } from './configurations/menu/menu.component';
import { EditionConfigurationsComponent } from './configurations/edition/edition.component';
import { AddressGoogleMapComponent } from './forms/address-google-map/address-google-map.component';
import { PermissionsConfigurationsComponent } from './configurations/permissions/permissions.component';
import { PictureCardComponent } from './cards/picture/picture.component';
import { InputBrowseQrComponent } from './input-browse-qr/input-browse-qr.component';
import { InputSelectSearchComponent } from './input-select-search/input-select-search.component';
import { InputRadioSearchComponent } from './input-radio-search/input-radio-search.component';
import { IconStatusComponent } from './icon-status/icon-status.component';
import { WalletBadgeComponent } from './wallet-badge/wallet-badge.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';

const components =
	[
		PasswordToggleComponent,
		UserAvatarComponent,
		NoItemsComponent,
		SelectEditionComponent,
		ImageCropperComponent,
		TextEditorQuillComponent,
		HeaderPageComponent,
		PopoverHeaderComponent,
		PaginationComponent,
		FooterPageComponent,
		SidemMenuComponent,
		SideMenuHeaderComponent,
		SideMenuContentComponent,
		GoogleMapContainerComponent,
		InputBrowseComponent,
		AgeGroupsComponent,
		FormErrorsComponent,
		LoadingTableComponent,
		BarChartComponent,
		LineChartComponent,
		SimpleChartComponent,
		DashboardComponent,
		MenuConfigurationsComponent,
		EditionConfigurationsComponent,
		AddressGoogleMapComponent,
		PermissionsConfigurationsComponent,
		PictureCardComponent,
		InputBrowseQrComponent,
		InputSelectSearchComponent,
		InputRadioSearchComponent,
		IconStatusComponent,
		WalletBadgeComponent
	];

const componentsModules = [

]

@NgModule(
	{
		declarations: [components],
		exports: [
			components,
			componentsModules
		],
		imports: [
			CommonModule,
			IonicModule,
			PipesModule,
			FormsModule,
			ReactiveFormsModule,
			DirectivesModule,
			RouterModule,
			GoogleMapsModule,
			ScrollingModule,
			componentsModules
		]
	})
export class ComponentsModule { }
