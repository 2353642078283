import { ThemeService } from '@services/theme-css.service';
import { SessionService } from '@services/session.service';
import { StorageService } from '@services/storage.service';
import { Component, OnInit } from '@angular/core';

import { NavController, MenuController } from '@ionic/angular';
import { NavigationHistoryService } from '@services/navigation-history.service';

import dayjs from 'dayjs';

import 'dayjs/locale/pt';

import customParseFormat from 'dayjs/plugin/customParseFormat';

import { EnumAppMenu } from '@models/AppMenus';
import { SwUpdate } from '@angular/service-worker';
import { ProjectService } from '@services/project.service';
import { IProjectSettings } from '@models/ProjectSettings';
import { SEOService } from '@services/seo.service';

@Component(
	{
		selector: 'app-root',
		templateUrl: 'app.component.html',
		styleUrls: ['app.component.scss'],
	})
export class AppComponent implements OnInit {

	private installPrompt: any = null;

	public ProjectSettings: IProjectSettings;

	constructor(private NavController: NavController,
		private NavigationHistoryService: NavigationHistoryService,
		private SessionService: SessionService,
		private StorageService: StorageService,
		private ProjectService: ProjectService,
		private ThemeService: ThemeService,
		private SEOService: SEOService,
		private SwUpdate: SwUpdate,
		private MenuController: MenuController) {

	}

	async ngOnInit() {

		this.MenuController.enable(false);

		await this.NavigationHistoryService.Init();

		this.ProjectSettings = await this.ProjectService.Init();

		const projectPath = this.ProjectService.GetProjectPath();

		await this.ThemeService.Init();

		this.ThemeService.setTheme(projectPath);

		await this.StorageService.Init();

		await this.SessionService.Init();

		// this.SEOService.SetupGoogleAnalytics(this.ProjectSettings);

		// console.log(this.ProjectSettings);

		this.SEOService.UpdateProjectMetadata(this.ProjectSettings);

		// const isPWAInstalledOnDesktop: boolean = document.getElementsByClassName('plt-pwa').length > 0;

		// console.log(isPWAInstalledOnDesktop);

		// this.getInstallPrompt();

		// const r = await this.SwUpdate.checkForUpdate();

		// console.log('new version -> ',r);

		if (this.SwUpdate.versionUpdates) {
			this.SwUpdate.versionUpdates.subscribe((version) => {

				console.log(version);
				// "NO_NEW_VERSION_DETECTED"

				// if (confirm('A new version is available'))
				// 	window.location.reload();
			})

		}

		dayjs.locale('pt');
		dayjs.extend(customParseFormat);

		this.NavController.navigateRoot([EnumAppMenu.Initialize], { animated: false });

	}


	// private getInstallPrompt(): void {
	// 	window.addEventListener('beforeinstallprompt', (e) => {

	// 		e.preventDefault();
	// 		this.installPrompt = e;

	// 		const isPWAInstalledOnDesktop: boolean = document.getElementsByClassName('plt-pwa').length > 0;

	// 		console.log(isPWAInstalledOnDesktop);

	// 		// if (!isPWAInstalledOnDesktop)
	// 		// 	this.askUserToInstallApp();
	// 	})

	// }

	// askUserToInstallApp() {
	// 	this.installPrompt.prompt();
	// }
}
