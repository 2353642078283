import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeStyle, SafeUrl } from "@angular/platform-browser";

import dayjs from "dayjs";

@Pipe({ name: 'DefaultTextOrValue' })
export class DefaultTextOrValue implements PipeTransform {
    /**
     * Custom Pipe para formatação de valores undefined/null que possam ser binded numa view.
     * Caso o valor seja undefined/null/"" o valor é replaced pela string default: "Sem Informação".
     * Caso seja necessário, pode ser passado o defaultText como parametro no pipe com um texto personalizado.
     * @param value valor a ser renderizado
     * @param defaultText valor string para replace caso o "valor" seja undefined/null.
     * @returns string
     */
    transform(value: any, defaultText: string = "Sem Informação"): any {
        if (!value) return defaultText;

        return value;
    }
}

@Pipe({ name: 'DefaultTextOrArrayValue' })
export class DefaultTextOrArrayValue implements PipeTransform {
    /**
     * Custom Pipe para formatação de valores em listas de strings que possam ser binded numa view.
     * Caso o valor seja undefined/null/ou não existem valores, o valor é replaced pela string default: "Sem Informação".
     * Todos os valores undefined/null são removidos do array de valores antes do tratamento.
     * Caso seja necessário, pode ser passado o defaultText como parametro no pipe com um texto personalizado.
     * @param value array valor a ser renderizado
     * @param defaultText valor string para replace caso o "valor" seja undefined/null.
     * @returns string
     */
    transform(value: any[], defaultText: string = "Sem Informação"): any {
        if (!value) return defaultText;

        const stringValue = value.filter(x => x).join(', ');

        if (!stringValue) return defaultText;

        return stringValue;
    }
}

@Pipe({ name: 'DateFromNow' })
export class DateFromNow implements PipeTransform {
    /**
     * Custom Pipe para formatação de datas com tempo relativo.
     * Por exemplo "à 2 minutos", "à 3 dias", etc.
     * @param value data string ou Date Object.
     * @returns string
     */
    transform(value: string | Date): string {
        if (!value) return "Sem informação";

        const date = dayjs(value);

        if (!date.isValid()) return "Sem informação";

        const relative = date.fromNow();

        return relative;
    }
}

@Pipe({ name: 'SanitizeHtml' })
export class SanitizeHTML {
    constructor(private sanitizer: DomSanitizer) { }

    /**
     * Pipe que marca string HTML como segura para injeção e bind em elemento DOM.
     * @param value 
     * @returns 
     */
    transform(value) {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}


@Pipe({ name: 'FilterByFields' })
export class FilterByFields implements PipeTransform {
    /**
     * 
     * @param array 
     * @param text 
     * @param field 
     * @returns 
     */
    transform(array: any[], text: string, fields: string[] = ["Description", "Code"]): any {
        if (text)
            array = array.filter(it => fields.some(field => it[field].toLowerCase().includes(text.toLocaleLowerCase())))

        return array;
    }
}


@Pipe({
    name: 'security'
})
export class SecurityPipe implements PipeTransform {

    url: SafeResourceUrl;
    constructor(protected sanitizer: DomSanitizer) {

    }

    public transform(value: string, type: string): SafeHtml | SafeStyle | SafeUrl | SafeResourceUrl {

        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                this.url = this.sanitizer.bypassSecurityTrustUrl(value);
                return this.url;
            case 'resourceUrl':
                this.url = this.sanitizer.bypassSecurityTrustResourceUrl(value);
                return this.url;

            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }

}


@Pipe({ name: 'searchValueProperty' })
export class SearchValuePropertyPipe implements PipeTransform {
    transform(array: Array<any>, arg: string, property: any = 'Name', prop: any = null): any {
        console.log(property);
        let a: Array<any> = array ? [...[], ...array] : [];

        if (prop) {
            a = a.filter(item => arg && item[property][prop] ? item[property][prop].toLowerCase().match(arg.toLowerCase().trim()) : item);
            return a;
        }

        a = a.filter(item => arg && item[property] ? item[property].toLowerCase().match(arg.toLowerCase().trim()) : item);

        return a;
    }
}