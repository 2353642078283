import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';

@Component({
    selector: 'app-simple-list',
    templateUrl: './simple-list.component.html',
    styleUrls: ['./simple-list.component.scss'],
})
export class SimpleListModalComponent implements OnInit {

    public Title: string = "";
    public Data: any[] = [];

    constructor(
        private NavParams: NavParams,
        private ModalController: ModalController) {

        this.Title = this.NavParams.get('title');
        this.Data = this.NavParams.get('data');


        console.log(this.Data);

    }


    ngOnInit() { }

    Close() {

        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
    }

}
