import { ProjectService } from '@services/project.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import EmbedJSONMapStyle from '@assets/maps/style.json';
import EmbedJSONMapStyleDark from '@assets/maps/style_dark.json';
import EmbedJSONMapStyleLight from '@assets/maps/style_light.json';

//https://github.com/angular/components/tree/main/src/google-maps#readme

export enum EnumMapStyle {
	DEFAULT = 'default',
	LIGHT = 'light',
	DARK = 'dark'
}

@Injectable({
	providedIn: 'root'
})
export class GoogleMapsService {

	private ApiLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private apiLoaded = this.ApiLoaded.asObservable();

	private loadedGoogleMaps: Subscription;

	constructor(private HttpClient: HttpClient, private ProjectService: ProjectService) {

	}

	// CARREGAMENTO INICIAL DO GOOGLE MAPS
	async Init() {

		if (this.loadedGoogleMaps) return Promise.resolve(this.loadedGoogleMaps);

		const googleKey = this.ProjectService.GetSettings()?.GoogleMaps?.Key || '';

		this.loadedGoogleMaps = await this.HttpClient.jsonp(`https://maps.googleapis.com/maps/api/js?libraries=geometry&sensor=false&key=${googleKey}&region=PT&callback=initMap`, 'callback')
			.pipe(
				map(() => true),
				catchError(() => of(false))
			)
			.subscribe(
				() => {
					console.log('🚀 ~ GoogleMapsService ~ google maps api loaded');
					this.ApiLoaded.next(true);
				}
			);

		return this.loadedGoogleMaps;
	}

	/**
	 * DEVOLVE OBSERVABLE DO MAPA
	 * @returns 
	 */
	GetMap() {
		return this.apiLoaded;
	}



	/**
	 * 
	 * @param style 
	 * @returns 
	 */
	GetMapStyle(style: EnumMapStyle.DEFAULT | EnumMapStyle.DARK | EnumMapStyle.LIGHT = EnumMapStyle.DEFAULT): google.maps.MapTypeStyle[] {
		//SETUP STYLE FROM JSON FILE
		// return new google.maps.StyledMapType(JSON.parse(JSON.stringify(EmbedJSONMapStyle)), { "name": 'StyledMap' });

		return (style == EnumMapStyle.DARK) ? EmbedJSONMapStyleDark : (style == EnumMapStyle.LIGHT) ? EmbedJSONMapStyleLight : EmbedJSONMapStyle;
	}

	/**
	 * 
	 * @param street 
	 * @returns 
	 */
	async GetCoordenatesFromStreetName(street: string = "") {

		const googleKey = this.ProjectService.GetSettings()?.GoogleMaps?.Key || '';

		// https://maps.google.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA
		const http = this.HttpClient.get<any>(`https://maps.google.com/maps/api/geocode/json?address=${street}&key=${googleKey}&region=PT`);

		return firstValueFrom(http)
			.then((response: any) => {
				console.log(`Result GET : `, response);

				return response?.status == 'OK' ? response?.results : [];
			})
			.catch(error => {
				console.log(`Error GET : `, error);

				return [];
			});
	}

}
