// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment =
{
	production: false,
	EncryptStorage: true,

	Author: {
		Website: "https://boldapps.pt/",
		Name: "Boldapps",
		Logo: {
			Footer: "assets/projects/boldapps_grey.svg"
		}
	},
	Integration:
	{
		API:
		{
			// URL: "https://localhost:44311/"
			URL: "https://demo.api.smartcardcitizen.boldapps.pt/bo/",
		}
	},
	// Global:
	// {
	// 	IdUser: 0, // ????? perguntar ao graça para depois validar
	// 	// IdEntity: '1' // referencia ao projeto
	// },
	DebugProject: "boldapps",
	UserTest:
	{
		Username: "",
		Password: ""
	},
	Version: '0.1.0-demo'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
