import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';

@Component({
	selector: 'app-observation-text',
	templateUrl: './observation-text.component.html',
	styleUrls: ['./observation-text.component.scss'],
})
export class ObservationTextComponent implements OnInit {

	public HeaderTitle: string = "";
	public SubHeaderText: string = "";
	// public InputTitle: string = "Motivo";
	public IsDisabled: boolean = false;
	public Text: string = "";
	public TextCopy: string = "";
	private onSave: Function = null;
	private onShowToast: Function = null;

	public Form: FormGroup;

	constructor(
		private ModalController: ModalController,
		private NavParams: NavParams
	) {

		this.HeaderTitle = this.NavParams.get('headerTitle') || this.HeaderTitle;
		this.SubHeaderText = this.NavParams.get('subHeaderText') || this.SubHeaderText;
		// this.InputTitle = this.NavParams.get('inputTitle') || this.InputTitle;
		this.IsDisabled = this.NavParams.get('isDisabled') || this.IsDisabled;
		this.Text = this.NavParams.get('text') || this.Text;
		this.TextCopy = this.NavParams.get('text') || this.TextCopy;
		this.onSave = this.NavParams.get('onSave') || this.onSave;
		this.onShowToast = this.NavParams.get('onShowToast') || this.onShowToast;

		this.onCreateFormElement();
	}


	ngOnInit() { }

	private onCreateFormElement() {

		this.Form = new FormGroup({
			Description: new FormControl(null, []),
			tempDescription: new FormControl(null, []),
			DescriptionPrivate: new FormControl(null, []),
			tempDescriptionPrivate: new FormControl(null, [Validators.required]),
			Notify: new FormControl(null, [])
		});

	}

	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

	public OnChangeNotify(ev?: any) {

		this.Form.get('tempDescription').setValidators([]);

		if (this.Form.get('Notify').value) {
			this.Form.get('tempDescription').setValidators([Validators.required]);

			this.Form.get('tempDescription').setErrors({ required: true })
		}
		else {

			this.Form.get('tempDescription').setErrors(null)
		}

	}

	/**
	 * 
	 * @param ev 
	 */
	public OnChangeTextQuill(ev: any) {

		this.Form.get('tempDescription').setValue(ev);

		if (ev) {
			if (JSON.stringify(this.Form.get('tempDescription').value) != JSON.stringify(this.Form.get('Description').value))
				this.Form.get('tempDescription').markAsDirty();

		}
	}


	/**
	 * 
	 * @param ev 
	 */
	public OnChangeTextQuillPrivate(ev: any) {

		this.Form.get('tempDescriptionPrivate').setValue(ev);

		if (ev) {
			if (JSON.stringify(this.Form.get('tempDescriptionPrivate').value) != JSON.stringify(this.Form.get('DescriptionPrivate').value))
				this.Form.get('tempDescriptionPrivate').markAsDirty();

		}
	}

	/**
	 * 
	 * @param ev 
	 */
	public async OnSave(ev: any) {

		console.log(this.Form);

		if (!this.onShowToast) return;

		if (!this.Form.dirty) {

			return this.onShowToast('Não foram encontradas alterações.');
		}

		this.Form.markAllAsTouched();

		if (this.Form.invalid) {

			return this.onShowToast('Preencha todos os campos assinalados.');
		}



		const data = {
			observations: this.Form.get('Notify').value ? (this.Form.get('tempDescription').value || '') : '',
			observationsPrivate: this.Form.get('tempDescriptionPrivate').value || '',
			notify: this.Form.get('Notify').value ?? false
		}

		if (this.onSave) {

			const response = await this.onSave(data);

			console.log(response);

			if (response)
				this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Confirm);


		}

	}
}
