<ion-header>
	<ion-toolbar class="ion-padding-end">
		<!-- <ion-title>Imagem</ion-title> -->

		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding padding-horizontal">

	<ion-grid>

		<form [formGroup]="Form">
			<ion-row>
				<!-- <ion-col size="12" sizeMd="6">
					<ion-item class="full" lines="full">
						<ion-label position="stacked" i18n=":@@component_issue_new_card.nif" required>NIF
						</ion-label>
						<ion-input formControlName="VatNumber"></ion-input>
					</ion-item>
					<form-errors [control]="Form.get('VatNumber')"></form-errors>
				</ion-col> -->
				<ion-col size="12">
					<ion-label>
						<b i18n=":@@component_issue_new_card.card-date-issue">Validade do cartão</b>
					</ion-label>
				</ion-col>

				<ion-col size="12" sizeMd="4">

					<ion-item class="full" lines="none" fill="outline">
						<ion-label position="stacked" i18n=":@@component_issue_new_card.start" required>Válido após
						</ion-label>
						<ion-input [id]="'datepicker-movement-crud-datestart'"
							[disabled]="Form.get('StartDate').disabled" inputmode="none"
							[value]="(Form.get('StartDate').value|date:'dd-MM-yyyy')" button readonly>
							<ion-icon name="calendar-outline"></ion-icon>
						</ion-input>

						<ion-popover [trigger]="'datepicker-movement-crud-datestart'" show-backdrop="false">
							<ng-template>
								<ion-datetime presentation="date" [min]="MinDate"
									[max]="Form.get('EndDate').value||MaxDate" locale="pt" mode="md"
									formControlName="StartDate" [showDefaultButtons]="false">
								</ion-datetime>
							</ng-template>
						</ion-popover>

					</ion-item>

					<form-errors [control]="Form.get('StartDate')"></form-errors>
				</ion-col>
				<ion-col size="12" sizeMd="4">

					<ion-item class="full" lines="none" fill="outline">
						<ion-label position="stacked" i18n=":@@component_issue_new_card.end" required>Válido até
						</ion-label>
						<ion-input [id]="'datepicker-movement-crud-dateend'" [disabled]="Form.get('EndDate').disabled"
							inputmode="none" [value]="(Form.get('EndDate').value|date:'dd-MM-yyyy')" button readonly>
							<ion-icon name="calendar-outline"></ion-icon>
						</ion-input>

						<ion-popover [trigger]="'datepicker-movement-crud-dateend'" show-backdrop="false">
							<ng-template>
								<ion-datetime presentation="date" [min]="Form.get('StartDate').value||MinDate"
									[max]="MaxDate" locale="pt" mode="md" formControlName="EndDate"
									[showDefaultButtons]="false">
								</ion-datetime>
							</ng-template>
						</ion-popover>

					</ion-item>

					<form-errors [control]="Form.get('EndDate')"></form-errors>
				</ion-col>

			</ion-row>

			<ion-row>
				<ion-col size="12">
					<ion-item class="full" lines="full" fill="outline"
						[ngClass]="{'disabled': Form.get('description')?.disabled}">
						<ion-label position="stacked" i18n=":@@component_issue_new_card.description">Observações
						</ion-label>
						<text-editor-quill [source]="Form.get('Observations').value"
							[disabled]="Form.get('Observations')?.disabled" (callback)="OnChangeTextQuill($event)">
						</text-editor-quill>
					</ion-item>

					<form-errors [control]="Form.get('tempObservations')"></form-errors>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="12" sizeMd="4">
					<ion-item lines="none" fill="outline">
						<ion-label position="stacked" required i18n=":@@component_issue_new_card.notification">Notificar
							por
						</ion-label>
						<ion-select class="outline" formControlName="NotificationType" interface="popover">
							<ion-select-option [value]="item?.Key" *ngFor="let item of Notifications">
								{{item?.Value||''}}
							</ion-select-option>
						</ion-select>
					</ion-item>
					<form-errors [control]="Form.get('NotificationType')"></form-errors>
				</ion-col>
			</ion-row>

		</form>

	</ion-grid>

</ion-content>
<ion-footer>
	<ion-toolbar class="ion-padding-horizontal">
		<ion-button style="margin-right: -16px;" slot="end" fill="solid" color="medium" (click)="OnSave($event)">Emitir
			cartão
		</ion-button>
	</ion-toolbar>
</ion-footer>