<ion-list-header class="card">
	<ion-item class="full">
		<ion-grid class="full">

			<ion-row class="ion-justify-content-center">
				<ion-col>
					<ion-img button [routerLink]="[HomePath]" class="logo" [src]="ProjectLogo"></ion-img>
				</ion-col>
				<ion-col>
					<ion-buttons class="ion-justify-content-end">
						<ion-button [routerLink]="[NotificationPath]" routerLinkActive="selected">
							<ion-icon name="notifications-outline" slot="icon-only"></ion-icon>
						</ion-button>
					</ion-buttons>
				</ion-col>
			</ion-row>

		</ion-grid>

	</ion-item>
</ion-list-header>