<ion-header>
    <ion-toolbar class="ion-padding-end">
        <!-- <ion-title i18n=":@@merchant_search_table.page-title">Adicionar Gestor</ion-title> -->

        <ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>

    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding padding-horizontal">
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-item class="full" lines="full" fill="outline">
                    <ion-label position="stacked" i18n=":@@select_file_upload.upload" required>Ficheiro
                    </ion-label>

                    <input-browse [fileName]="File?.OriginalName" [fileURL]="File?.Path" [accept]="'.xlsx'"
                        (callback)="OnChangeFile($event)"></input-browse>

                    <a [href]="FileTemplate">descarregar ficheiro template</a>
                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="Modules" class="ion-margin-top">

            <ion-radio-group [value]="SelectedModule" (ionChange)="ChangeModule($event)">

                <ion-item>
                    <ion-label position="stacked" required>Módulos</ion-label>
                    <ion-grid>
                        <ion-row>
                            <ion-col *ngFor="let item of Modules">
                                <ion-item lines="none">
                                    <ion-label>{{item?.Name}}</ion-label>
                                    <ion-radio slot="start" [value]="item"></ion-radio>
                                </ion-item>
                            </ion-col>

                        </ion-row>
                    </ion-grid>
                </ion-item>
            </ion-radio-group>

        </ion-row>
        <ion-row>
            <ion-col class="flex-center-custom">
                <ion-button i18n=":@@select_file_upload.process_btn" (click)="OnUploadFile()">Carregar
                    ficheiro</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>