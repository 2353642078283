import { Component, Input, OnInit } from '@angular/core';

@Component(
{
	selector: 'no-items',
	templateUrl: './no-items.component.html',
	styleUrls: ['./no-items.component.scss'],
})
export class NoItemsComponent implements OnInit 
{
    @Input('description') Title:string = "sem registos";

    @Input('icon') Icon:string = "file-tray-outline";

	constructor() { }

	ngOnInit() { }
}
