<ion-header>
	<ion-toolbar class="ion-padding-end">
		<ion-title i18n=":@@merchant_search_table.page-title">Adicionar Gestor</ion-title>

		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding padding-horizontal">


	<ion-toolbar>
		<ion-searchbar #filterText [value]="Filters?.Text" placeholder="pesquisar" (ionClear)="OnSearch($event)"
			(keyup.enter)="OnSearch($event, filterText?.value)">
		</ion-searchbar>

		<ion-buttons slot="end">
			<ion-button fill="clear" color="primary" (click)="OnSearch($event, filterText?.value)">
				<ion-icon slot="icon-only" name="search-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>

	<ion-grid class="table">

		<ion-row class="table-header">
			<ion-col class="flex-align-center" i18n=":@@merchant_search_table.name">Nome</ion-col>
			<ion-col size="4" class="flex-align-center" i18n=":@@merchant_search_table.manager">Email</ion-col>
			<ion-col size="2" class="flex-align-center"></ion-col>
		</ion-row>

		<ng-container *ngFor="let item of Data;">

			<ion-row class="table-body with-button-opacity">
				<ion-col class="flex-align-center">{{ item?.Name || '--'}}</ion-col>

				<ion-col size="4" class="flex-align-center">
					{{item?.Email || '--'}}
				</ion-col>


				<ion-col size="2" class="flex-justify-end">

					<ion-button fill="clear" size="small" (click)="OnSaveAssociation(item)">
						<ion-icon name="add-outline" slot="icon-only"></ion-icon>
					</ion-button>
					<!-- 
				<ion-button [disabled]="item?.Id==SeletedEdition?.Id" fill="clear" size="small"
					(click)="OnDelete(item)">
					<ion-icon  name="trash-outline" slot="icon-only"></ion-icon>
				</ion-button> -->

				</ion-col>
			</ion-row>

		</ng-container>


		<div *ngIf="Data?.length==0" style="margin-top: 32px; opacity: 0.7;">
			<ion-icon name="search-outline"></ion-icon>
			<p i18n=":@@merchant_search_table.no-it">procure pelo nome de gestor</p>
		</div>

	</ion-grid>

</ion-content>