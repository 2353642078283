<ng-container *ngFor="let p of appPages; let i = index">

	<ng-container *ngIf="!p.Children || p.Children?.length == 0">

		<ion-menu-toggle auto-hide="false">
			<ion-item *ngIf="p.NavigationPath" routerDirection="root" [routerLink]="[p.NavigationPath]" lines="none"
				[queryParams]="p?.QueryParams||''" detail="false" routerLinkActive="selected" (click)="OnSetModules(p)">
				<!-- <ion-icon slot="start" [ios]="p.Icon + '-outline'" [md]="p.Icon + '-sharp'"></ion-icon> -->
				<ion-icon slot="start" [name]="p.NavigationIcon"></ion-icon>
				<ion-label>{{ p.Name }}</ion-label>
			</ion-item>
		</ion-menu-toggle>

	</ng-container>

	<ng-container *ngIf="p.Children?.length > 0">

		<ion-list-header class="ion-margin-top" *ngIf="p.Children?.length > 0">{{ p.Name }}</ion-list-header>

		<ion-menu-toggle auto-hide="false" *ngFor="let item of p.Children">
			<ion-item *ngIf="item.NavigationPath" routerDirection="root" [routerLink]="[item.NavigationPath]"
				[queryParams]="item?.QueryParams||''" lines="none" detail="false" routerLinkActive="selected"
				(click)="OnSetModules(item)">
				<!-- <ion-icon slot="start" [ios]="item.Icon + '-outline'" [md]="item.Icon + '-sharp'"></ion-icon> -->
				<ion-icon slot="start" [name]="item.NavigationIcon"></ion-icon>
				<ion-label>{{ item.Name }}</ion-label>
			</ion-item>
		</ion-menu-toggle>

	</ng-container>

</ng-container>