import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { Component, OnInit, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PresentImageModalComponent } from '@modals/present-image/present-image.component';

@Component({
	selector: 'image-cropper-render',
	templateUrl: './image-cropper.component.html',
	styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit, OnChanges {

	@Input() data;
	@Input() disabled;
	@Input() presentModal: Function = new Function();
	@Output() callback = new EventEmitter<string>();

	public DefaultImage: string;
	imageChangedEvent: any;
	croppedImage: string;

	modal: HTMLElement;

	constructor(
		private ModalController: ModalController
	) { }



	ngOnInit(): void {
		this.DefaultImage = 'assets/project/default_image.jpg';
	}

	ngOnChanges(e): void {
		console.log(e, this.data);

		// this.DefaultImage = 'assets/project/default_image.jpg';
	}

	async OnPresentModal() {

		const { role, data } = await this.presentModal();

		// console.log(modal);

		// await modal.present();

		// const { role, data } = await modal.onDidDismiss()

		// console.log(role, data);


		if (role == EnumGenericRoleActionAlerts.Save) {
			this.data = data;

			if (this.callback) this.callback.emit(this.data);

		}

	}

	/**
	 * 
	 * @param ev 
	 * @returns 
	 */
	async OnViewImage(ev: any) {

		ev?.preventDefault();
		ev?.stopPropagation();

		const modal = await this.ModalController.create({
			component: PresentImageModalComponent,
			componentProps: {
				Image: this.data
			},
			cssClass: 'default'
		});

		await modal.present();

		return await modal.onDidDismiss();
	}

	OnDeleteImage() {

		this.data = null;

		if (this.callback)
			this.callback.emit(this.data);
	}
}