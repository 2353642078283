<ion-header [translucent]="true">
    <ion-toolbar class="ion-padding-end">
        <ion-title *ngIf="Title" slot="start">{{Title}}</ion-title>
        <ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>

    </ion-toolbar>

    <ion-toolbar>
        <ion-grid class="ion-no-padding">
            <ion-row class="ion-padding-horizontal">
                <ion-searchbar #search></ion-searchbar>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [fullscreen]="true">

    <ion-grid class="ion-no-padding">



        <ng-container *templateVar="(Data | searchValueProperty: search.value) as Data">

            <ion-row class="ion-padding-horizontal">
                <ion-list style="width: 100%" class="ion-padding-horizontal">

                    <ion-item class="ion-no-margin" *ngFor="let item of Data">
                        {{item?.Name||'--'}}
                    </ion-item>

                </ion-list>
            </ion-row>

            <no-items *ngIf="Data?.length==0"></no-items>

        </ng-container>

    </ion-grid>

</ion-content>