import { URLUtils } from '@utils/URLUtils';
import { IMenuTabModel, IMenusModel, MenuTabModel } from '@models/AppMenus';
import { Observable, BehaviorSubject } from 'rxjs';
import { WebService } from '@services/web.service';
import { Injectable } from '@angular/core';
import { IAPIResponse } from '@api/AbstractAPI';

@Injectable({
	providedIn: 'root'
})
export class MenuService {

	private menus: IMenusModel[];

	public Menus: Observable<IMenusModel[]>;

	private selectedMenu: IMenusModel;

	private menufinded: { path: string, menuData: IMenusModel } = { path: null, menuData: null };

	private menusBehaviorSubject: BehaviorSubject<IMenusModel[]>;

	private plaforms: any[];

	constructor(
		private WebService: WebService
	) {

		this.menusBehaviorSubject = new BehaviorSubject<IMenusModel[]>(this.menus);

		this.Menus = this.menusBehaviorSubject.asObservable();

		this.Menus.subscribe(menus => {

			this.menus = menus;
		});
	}

	async Init() {

		this.menus = [];

		await this.GetPlatformList();

		await this.getMenus();

		// if (environment.production == false)
		// 	await this.getMenuListModules();

		// console.log(this.menus);

	}

	private async getMenus(): Promise<IMenusModel[]> {

		if (this.menus?.length > 0) return Promise.resolve(this.menus);

		const result = await this.WebService.GetNavigationList();

		this.menus = result?.Result || [];

		this.menusSetParams(this.menus);

		this.menusBehaviorSubject.next(this.menus);

		return this.menus;

	}

	private menusSetParams(data: IMenusModel[]) {

		data.forEach(el => {

			if (el.NavigationPath) {

				const url = new URL(window.location.origin + (el?.NavigationPath[0] != "/" ? ("/" + el?.NavigationPath) : el?.NavigationPath));

				const search = Object.fromEntries(url.searchParams as any);

				// console.log(el.NavigationPath);

				// console.log(url, search);

				el['NavigationPath'] = url?.pathname || '';
				el['QueryParams'] = search || '';

			}

			if (el.Children.length > 0)
				this.menusSetParams(el.Children);


		})
	}

	public async GetMenus() {

		return await this.getMenus();
	}

	public SetMenuSelected(menu: IMenusModel) {

		this.selectedMenu = menu || null;

		this.WebService.SetMenu(this.selectedMenu?.Id || null);
	}

	/**
	 * 
	 * @param path 
	 * @returns 
	 */
	public SetMenuByPath(path: string = null): boolean {

		path = URLUtils.RemoveLanguageReference(path);

		this.selectedMenu = this.findMenu(path);

		this.WebService.SetMenu(this.selectedMenu?.Id || null);

		return this.selectedMenu ? true : false;
	}

	/**
	 * 
	 * @param path 
	 */
	public GetMenuByPath(path: string = null): IMenusModel {

		path = URLUtils.RemoveLanguageReference(path);

		return this.findMenu(path);


	}

	/**
	 * 
	 * @param path 
	 * @returns 
	 */
	public GetPermissionsMenuByPath(path: string = null, idTAB: number = null): number[] {

		// console.log(path, idTAB);

		path = URLUtils.RemoveLanguageReference(path);

		const menu: IMenusModel = this.findMenu(path);

		const operations: number[] = idTAB && menu?.Children?.length > 0 ? menu.Children.find(el => el.NavigationPath && el.QueryParams?.id && Number(el.QueryParams?.id) == idTAB)?.PermittedOperationList || [] : menu?.PermittedOperationList || [];

		// console.log(idTAB, operations);

		return operations || [];

	}

	/**
	 * SEARCH MENU BY PATH
	 * @param path 
	 * @param data 
	 * @param isntChild
	 * @param searchRemovedPath
	 * @returns 
	 */
	private findMenu(path: string, data: IMenusModel[] = this.menus, isntChild: boolean = false, searchRemovedPath: boolean = false) {

		if (!isntChild && path == this.menufinded?.path) return this.menufinded?.menuData;

		let menuSelected = null;

		if (data?.length == 0) return null;

		for (let index = 0; index < data.length; index++) {

			const menu = data[index];

			if (("/" + menu.NavigationPath).indexOf(path) === 1) {
				menuSelected = menu;
				break;
			}
			else if (menu.Children?.length > 0) {
				menuSelected = this.findMenu(path, menu.Children, true);
				if (menuSelected) break;
			}

		}

		// console.log("---------------------------------------------",path);


		if (!menuSelected && !searchRemovedPath && !isntChild) {

			const fixedPaths = ['/view', '/add', '/edit', '/list', '/general', '/import'];

			const removedPath = fixedPaths.find(it => path.includes(it));

			path = removedPath ? path.split(removedPath)[0] : path;

			//#region VIEW MAIN MODULE PERMISSIONS

			const parts = path.split('/');

			if (removedPath && parts.length > 3) {
				parts.pop();
				path = parts.join('/');
			}

			menuSelected = this.findMenu(path, data, false, true);
		}

		if (menuSelected)
			this.menufinded = { path: path, menuData: menuSelected };

		return menuSelected;

	}

	/**
	 * 
	 * @param idPlatform 
	 * @returns 
	 */
	public async GetMenuList(idPlatform?: number): Promise<IAPIResponse<any>> {

		const response = await this.WebService.GetMenuList(idPlatform);

		return response;

	}

	/**
	 * 
	 * @param idMenu 
	 * @returns 
	 */
	public async GetMenu(idMenu: number): Promise<IAPIResponse<any>> {

		const response = await this.WebService.GetMenu(idMenu);

		return response;

	}

	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	public async SaveMenu(data): Promise<IAPIResponse<any>> {

		const response = await this.WebService.SaveMenu(data);

		return response;

	}

	/**
	 * 
	 * @param menus 
	 * @returns 
	 */
	public async ChangePositionMenu(menus: { Id: string, Position: number, IdParent?: string }[]): Promise<IAPIResponse<any>> {

		const response = await this.WebService.ChangePositionMenu(menus);

		return response;

	}

	/**
	 * 
	 * @param idMenu 
	 * @returns 
	 */
	public async DeleteMenu(idMenu: number): Promise<IAPIResponse<any>> {

		const response = await this.WebService.DeleteMenu(idMenu);

		return response;

	}

	public async GetPlatformList(): Promise<any[]> {

		if (this.plaforms && this.plaforms.length > 0) return Promise.resolve(this.plaforms);

		const response = await this.WebService.GetPlatformList();

		this.plaforms = response?.Result || [];

		return this.plaforms;

	}

	/**
	 * 
	 * @returns 
	 */
	public async GetMenuListModules(): Promise<any[]> {

		const response = await this.WebService.GetMenuListModules();

		const data = response?.Result || [];

		// console.log(data);

		return data;

	}


	/**
	 * 
	 * @param idModule 
	 * @param idMenu 
	 * @returns 
	 */
	public async GetOperationList(idModule?: string, idMenu?: string): Promise<any[]> {

		const response = await this.WebService.GetOperationList(idModule, idMenu);

		const data = response?.Result || [];

		// console.log(data);

		return data;

	}

	/**
	 * 
	 * @returns 
	 */
	public async GetMenuTabList(): Promise<IMenuTabModel[]> {

		const response = await this.WebService.GetMenuTabList();

		const data = (response?.Result || []).map(it => new MenuTabModel(it)).sort((a, b) => (+(a?.NavigationPosition) > +(b?.NavigationPosition) ? -1 : 1) * -1);

		// console.log(data);

		return data;

	}

	/**
	 * 
	 * @param idParent 
	 * @param idModule 
	 * @returns 
	 */
	public async GetSimpleMenuListPermissions(idParent?: string, idModule?: number): Promise<any> {

		const response = await this.WebService.GetSimpleMenuListPermissions(idParent, idModule);

		return response;

	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	public async GetRoleUserPermissionTemplate(id: string): Promise<any> {

		const response = await this.WebService.GetRoleUserPermissionTemplate(id);

		return response;

	}

	public GetMenuTitle(): string {

		return this.menufinded?.menuData.Name || "";
	}
}
