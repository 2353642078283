import { Directive, ViewContainerRef, TemplateRef, Input } from '@angular/core';

@Directive(
{
    selector: '[templateVar]',
})
export class TemplateVarDirective
{
    @Input()
    set templateVar(context: any)
    {
        this.context.$implicit = this.context.templateVar = context;

        this.updateView();
    }

    context: any = {};

    constructor(private ViewContainerRef: ViewContainerRef, private TemplateRef: TemplateRef<any>) { }

    updateView()
    {
        this.ViewContainerRef.clear();
        this.ViewContainerRef.createEmbeddedView(this.TemplateRef, this.context);
    }
}
