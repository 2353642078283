<ng-container>

	<google-map [class]="Class" [id]="id" [options]="Options" [center]="Options?.center"
		style="height: 100% !important;">

		<map-marker #markerElem="mapMarker" *ngFor="let marker of Markers" [position]="marker.position"
			[label]="marker.label" [title]="marker?.title" [options]="marker?.options"
			(mapDragend)="OnDrag($event, marker)" (mapClick)="OnOpenInfo(markerElem, marker.info)">
		</map-marker>

		<map-kml-layer #kmlLayer="mapKmlLayer" [hidden]="!PolygonUrl" [url]="PolygonUrl">
		</map-kml-layer>

		<!-- <map-info-window>{{ infoContent }}</map-info-window> -->
	</google-map>

</ng-container>