import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { IKeyValue } from '@models/Common';
import { IConsumerIssueCardModel } from '@models/Consumers';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';

enum ENUM_TYPES_NOTIFICATION {
	EMAIL = 2,
	LETTER = 5
}

@Component({
	selector: 'app-issue-card',
	templateUrl: './issue-card.component.html',
	styleUrls: ['./issue-card.component.scss'],
})
export class IssueCardComponent implements OnInit {

	public Form: FormGroup;

	public ENUM_TYPES_NOTIFICATION = ENUM_TYPES_NOTIFICATION;

	public Notifications: IKeyValue[] = []

	public MinDate;
	public MaxDate;

	private showToast: Function;
	private issueCard: Function;

	constructor(
		private NavParams: NavParams,
		private ModalController: ModalController) {

		const idWallet = this.NavParams.get('idWallet') || '';
		this.showToast = this.NavParams.get('showToast') || null;
		this.issueCard = this.NavParams.get('issueCard') || null;

		console.log(idWallet);

		this.Notifications = [
			{ Key: String(ENUM_TYPES_NOTIFICATION.EMAIL), Value: 'Email' },
			{ Key: String(ENUM_TYPES_NOTIFICATION.LETTER), Value: 'Carta' },
		]

		this.MinDate = new Date().toISOString();

		const current = new Date();
		current.setFullYear(current.getFullYear() + 8);

		this.MaxDate = current.toISOString();

		this.Form = new FormGroup({
			IdWallet: new FormControl(idWallet || null, [Validators.required]),
			StartDate: new FormControl(null, [Validators.required]),
			EndDate: new FormControl(null, [Validators.required]),
			Observations: new FormControl(),
			tempObservations: new FormControl(),
			NotificationType: new FormControl(null, [Validators.required]),
		});

	}

	ngOnInit() { }


	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

	/**
	 * 
	 * @param ev 
	 */
	public OnChangeTextQuill(ev: any) {

		this.Form.get('tempObservations').setValue(ev);



	}

	public async OnSave(ev: any) {

		console.log(this.Form);

		if (!this.Form.dirty) {

			if (this.showToast) this.showToast($localize`:@@TOAST_component_issue_new_card.without-changes:Não foram encontradas alterações.`);

			return;
		}

		this.Form.markAllAsTouched();

		if (this.Form.invalid) {
			if (this.showToast) this.showToast($localize`:@@TOAST_component_issue_new_card.required-fields:Preencha os campos em falta.`);

			return;
		}


		const data: IConsumerIssueCardModel =
		{
			IdWallet: this.Form.get('IdWallet').value || '',
			StartDate: this.Form.get('StartDate').value || '',
			EndDate: this.Form.get('EndDate').value || '',
			Observations: this.Form.get('tempObservations').value || '',
			IdNotificationSubmissionType: Number(this.Form.get('NotificationType').value) || null,
		};



		if (this.issueCard) {

			const response = await this.issueCard(data);

			console.log(response);

			if (response)
				this.ModalController.dismiss(data, EnumGenericRoleActionAlerts.Confirm);
		}
	}
}
