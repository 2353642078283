<ion-header>
	<ion-toolbar class="ion-padding-end">
		<!-- <ion-title>{{HeaderTitle}}</ion-title> -->

		<ion-button title="fechar" style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end"
			(click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding">

	<ion-grid class="full ion-no-margin ion-no-padding">
		<form [formGroup]="Form">

			<ion-row *ngIf="SubHeaderText">
				<ion-col>
					<p>{{SubHeaderText}}</p>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="12">

					<ion-item class="full" lines="full" fill="outline"
						[ngClass]="{'disabled': Form.get('description')?.disabled}">
						<ion-label position="stacked" i18n=":@@modal_observation.motive" required>Motivo (Interno)
						</ion-label>
						<text-editor-quill [source]="Form.get('DescriptionPrivate').value"
							[disabled]="Form.get('DescriptionPrivate')?.disabled"
							(callback)="OnChangeTextQuillPrivate($event)">
						</text-editor-quill>
					</ion-item>

					<form-errors [control]="Form.get('tempDescriptionPrivate')"></form-errors>

				</ion-col>

				<ion-col>
					<ion-item class="full" lines="full" fill="outline">
						<ion-label> Notificar comerciante</ion-label>
						<ion-checkbox (ionChange)="OnChangeNotify($event)" formControlName="Notify"
							slot="start"></ion-checkbox>

					</ion-item>
				</ion-col>

				<ion-col size="12" [hidden]="!Form?.get('Notify').value">

					<ion-item class="full" lines="full" fill="outline"
						[ngClass]="{'disabled': Form.get('description')?.disabled}">
						<ion-label position="stacked" i18n=":@@modal_observation.motive-public" required>Motivo
							(Mensagem ao comerciante)
						</ion-label>
						<text-editor-quill [source]="Form.get('Description').value"
							[disabled]="Form.get('Description')?.disabled" (callback)="OnChangeTextQuill($event)">
						</text-editor-quill>
					</ion-item>

					<form-errors [control]="Form.get('tempDescription')"></form-errors>

				</ion-col>
			</ion-row>
		</form>
	</ion-grid>

</ion-content>
<ion-footer>
	<ion-toolbar class="ion-padding-horizontal">
		<ion-button style="margin-right: -16px;" slot="end" fill="solid" color="medium"
			i18n=":@@modal_observation.submit" (click)="OnSave($event)">Submeter
		</ion-button>
	</ion-toolbar>
</ion-footer>