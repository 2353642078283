import { Component, OnInit } from '@angular/core';
import { EnumAPIStatus } from '@api/AbstractAPI';
import { ModalController, NavParams } from '@ionic/angular';
import { IFilterList } from '@models/Content';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';

@Component({
	selector: 'app-select-user-profile',
	templateUrl: './select-user-profile.component.html',
	styleUrls: ['./select-user-profile.component.scss'],
})
export class SelectUserProfileModalComponent implements OnInit {
	public Data: any[] = [];

	private onSave: Function;

	private onGetUsers: Function;

	public Filters: IFilterList;

	constructor(
		private NavParams: NavParams,
		private ModalController: ModalController
	) {

		this.onSave = this.NavParams?.get('onSave') || null;

		this.onGetUsers = this.NavParams?.get('getUsers') || null;

		this.Filters = {
			PageNumber: 1,
			PageRows: 25,
			Text: ''
		};


	}

	async ngOnInit() {

		// if (this.onGetUsers)
		// 	this.Data = await this.onGetUsers();

	}

	async OnSearch(ev?: any, text: string = '') {

		if ((ev.type == 'ionClear' && this.Filters.Text == '') || (text == this.Filters.Text)) return;

		this.Filters.PageNumber = 1;
		this.Filters.Text = text;

		if (this.Filters.Text == '') return this.Data = [];

		if (this.onGetUsers)
			this.Data = await this.onGetUsers(this.Filters);
	}


	public async OnSaveAssociation(item) {

		if (this.onSave) {

			const result = await this.onSave([item.Id]);


			if (result?.Status == EnumAPIStatus.Success)
				this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Confirm);

			console.log(result);

		}
	}

	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

}
