import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, AfterContentInit } from '@angular/core';

@Component({
	selector: 'input-radio-search',
	templateUrl: './input-radio-search.component.html',
	styleUrls: ['./input-radio-search.component.scss'],
})
export class InputRadioSearchComponent implements OnInit {

	@Input('data') Data: any[] = [];
	@Input('disabled') IsDisabled: boolean = false;
	@Input('value') FieldValue: any = null;

	@Input('textPlaceholder') Placeholder: string = 'indique o valor';

	@ViewChild('popover') popover;

	@Output('callback') callback: EventEmitter<any> = new EventEmitter<any>();

	public IsOpen: boolean = false;

	constructor() { }

	ngOnInit() {
		console.log(this.FieldValue);

	}

	public OnPresentPopover(e: Event) {

		if (this.IsDisabled) return;

		this.popover.event = e;
		this.IsOpen = true;
	}

	OnChangeValue(item) {

		if (this.IsDisabled || item?.ListCAE?.length == 0) return;

		console.log(item?.detail?.value);

		const value = item?.detail?.value;

		this.FieldValue = value;

		this.IsOpen = false;

		if (this.callback)
			this.callback.emit(this.FieldValue);

	}

	trackByFn(index, item) {
		return index;
	}
}
