import { FormGroup } from '@angular/forms';
import { GoogleMapsService } from '@services/google-maps.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { AsyncUtils } from '@utils/AsyncUtils';

@Component({
	selector: 'address-google-map',
	templateUrl: './address-google-map.component.html',
	styleUrls: ['./address-google-map.component.scss'],
})
export class AddressGoogleMapComponent implements OnInit {

	@Input('form') FormGroup: FormGroup | any;
	@Input('mapOptions') MapOptions: any;
	@Input('mapId') MapId: string;
	@Input('label') Title: string;

	public Reset: boolean = false;

	constructor(private GoogleMapsService: GoogleMapsService) { }

	ngOnInit() {

		// console.log(this.FormGroup);

	}

	public async OnSearchStreet(formgroup: FormGroup) {


		if (
			!this.FormGroup.get('District').valid
			|| !this.FormGroup.get('Locality').valid
			|| !this.FormGroup.get('ZipCode').valid
			|| !this.FormGroup.get('Address').valid
		) return;
		// if (this.MapOptions.gestureHandling == 'none') return;

		const address = ((formgroup.get('Address').value || '') + ', ' + (formgroup.get('Locality').value || '') + ' ' + (formgroup.get('ZipCode').value || '') + ' ' + (formgroup.get('District').value || '')).trim().replace(/ /g, '+');

		// console.log(address);

		if (!address) return;

		const response: any[] = await this.GoogleMapsService.GetCoordenatesFromStreetName(address);

		console.log(response);

		formgroup.get('Coordinates').get('Latitude').setValue('');
		formgroup.get('Coordinates').get('Longitude').setValue('');

		if (!response || response.length == 0) return;

		const coords = response[0]?.geometry?.location || { lat: null, lng: null };

		// console.log(formgroup, coords);

		// formgroup.get('Locality').setValue();

		// formgroup.get('District').setValue();


		// formgroup.get('Address').setValue(response[0]?.formatted_address);

		formgroup.get('Coordinates').get('Latitude').setValue(coords?.lat);
		formgroup.get('Coordinates').get('Longitude').setValue(coords?.lng);

		formgroup.get('Coordinates').markAsDirty();
		formgroup.get('Coordinates').markAsTouched();

		formgroup.markAsDirty();
		formgroup.markAsTouched();
	}

	public OnEnabledMarker(formgroup: FormGroup) {

		// console.log(this.MapOptions);

		const tmp = Object.assign({}, this.MapOptions)

		// tmp.center = new google.maps.LatLng(
		// 	formgroup.get('Coordinates').get('Latitude').value,
		// 	formgroup.get('Coordinates').get('Longitude').value
		// );

		tmp.gestureHandling = tmp.gestureHandling == 'auto' ? 'none' : 'auto';

		if (tmp.gestureHandling == 'none') {
			formgroup.markAllAsTouched();
			formgroup.markAsDirty();
		}

		this.MapOptions = tmp;
	}

	public async OnCancelRepositionMarker(formgroup) {

		this.Reset = true;

		const tmp = Object.assign({}, this.MapOptions);

		// tmp.center = new google.maps.LatLng(
		// 	formgroup.get('Coordinates').get('Latitude').value,
		// 	formgroup.get('Coordinates').get('Longitude').value
		// );

		tmp.gestureHandling = 'none';

		this.MapOptions = tmp;

		await AsyncUtils.Sleep(100);

		this.Reset = false;
	}
}
