import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges } from '@angular/core';

@Component({
	selector: 'input-select-search',
	templateUrl: './input-select-search.component.html',
	styleUrls: ['./input-select-search.component.scss'],
})
export class InputSelectSearchComponent implements OnInit, OnChanges {

	@Input('data') Data: any[] = [];
	@Input('disabled') IsDisabled: boolean = false;
	@Input('value') FieldValue: string = '';

	@Input('textPlaceholder') Placeholder: string = 'indique o valor';

	@ViewChild('popover') popover;

	@Output('callback') callback: EventEmitter<any> = new EventEmitter<any>();

	public IsOpen: boolean = false;

	public SelectedItems: any[] = [];

	constructor() { }

	ngOnInit() {

		this.parseData();

	}
	ngOnChanges(ev) {

		this.parseData();
	}

	private parseData() {

		console.log(this.SelectedItems);

		const temp = this.FieldValue?.split(',');

		console.log(temp);


		this.Data.forEach(el => {

			el['Checked'] = temp?.some(it => it.includes(el.Code)) || false;

			if (el?.Checked) this.SelectedItems.push(el)

		});

	}

	public trackByFn(index, item) {
		return index;
	}

	public OnPresentPopover(e: Event) {

		if (this.IsDisabled) return;

		this.popover.event = e;

		this.IsOpen = true;
	}

	OnChangeValue(ev: any, item) {

		ev?.preventDefault();
		ev?.stopPropagation();

		// ev?.detail?.checked == ev?.detail?.value?.Checked -> fix problem with cdkVirtualFor
		if (this.IsDisabled || ev?.detail?.checked == ev?.detail?.value?.Checked) return;

		item['Checked'] = !item['Checked'];


		this.SelectedItems = this.Data.filter(it => it?.Checked);

		this.FieldValue = this.SelectedItems?.map(it => it?.Code).join(', ');

		if (this.callback)
			this.callback.emit(this.FieldValue);

	}
}
