import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IProjectSettings } from '@models/ProjectSettings';


/**
 * devolve baseHREF [ambiente desenvolvimento]
 * @param platformLocation 
 * @returns 
 */
export function GetBaseHref(platformLocation: PlatformLocation): string {

	const basehref = ((window.location?.hostname == "demo.admin.smartcardcitizen.boldapps.pt" || window?.location?.hostname == "localhost")
		&& ProjectService.GetProjectName()) ?
		("/" + ProjectService.GetProjectName() + "/") : platformLocation.getBaseHrefFromDOM();

	console.log(basehref);

	return basehref;
}

@Injectable({
	providedIn: 'root'
})
export class ProjectService {

	private settings: IProjectSettings;

	static PROJECTS: string[] = [
		"boldapps",
		"maia",
		"vianacastelo",
		"matosinhos",
		"vilavelharodao",
		"santarem"
	];

	constructor(private HttpClient: HttpClient) {

	}

	public async Init() {

		const path = this.GetProjectPath() + "/config.json?cache=" + new Date().getTime();

		// console.log(path);

		this.settings = await this.HttpClient.get<IProjectSettings>(path).toPromise();

		return this.settings;
	}

	/**
	 * 
	 * @returns 
	 */
	public GetGlobal() {
		return { IdEntity: this.settings?.Project?.Id || '' };
	}

	/**
	 * devolve nome de projeto
	 * @returns 
	 */
	static GetProjectName(): string {

		const projectName = window.location.pathname?.split('/')[1];

		const hasProject = ProjectService.PROJECTS.some(it => it == projectName) ? true : false;

		return hasProject ? projectName : null;
	}

	/**
	 * atribui path do projeto
	 * @param assetsPath 
	 * @returns 
	 */
	public GetProjectPath(assetsPath: "/images" | "/docs" | "" = ""): string {

		let basepath = "assets/projects/";

		// console.log(assetsPath);

		switch (window.location.hostname) {
			case "admin.smartcardcitizen.boldapps.pt": 				basepath = basepath + "boldapps"; break;
			case "demo.admin.smartcardcitizen.boldapps.pt": 		basepath = basepath + "boldapps"; break;
			case "cartaodevianagestao.cm-viana-castelo.pt": 		basepath = basepath + "vianacastelo"; break;
			case "localhost":
			case "192.168.100.169": 								basepath = basepath + (environment?.DebugProject != "" ? environment.DebugProject : "localhost"); break;
		}

		return basepath + assetsPath;
	}

	public GetSettings(): IProjectSettings {
		return this.settings;
	}
}
