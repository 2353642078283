import { ModalsModule } from '@modals/modals.module';
import { DirectivesModule } from '@directives/directives.module';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ComponentsModule } from '@components/components.module';

import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt-PT';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(localePT);

@NgModule(
    {
        declarations: [AppComponent],
        imports: [
            BrowserModule,
            HttpClientModule,
            HttpClientJsonpModule,
            IonicModule.forRoot({
                hardwareBackButton: false,
                backButtonIcon: 'chevron-back-outline',
                mode: 'md'
            }),
            AppRoutingModule,
            ComponentsModule,
            ModalsModule,
            IonicStorageModule.forRoot({
                name: "smartcard_citizen_bo",
                storeName: "smartcard_citizen_bo_db",
                // driverOrder: ['indexeddb', 'websql', 'sqlite']
            }),
            DirectivesModule,
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: false, //!isDevMode(),
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
            })
        ],
        providers: [
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            { provide: LOCALE_ID, useValue: 'pt-PT' }
        ],
        bootstrap: [AppComponent]
    })
export class AppModule { }
