import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IonReorderGroup, NavController } from '@ionic/angular';
import { EnumAppMenu } from '@models/AppMenus';
import { EnumActionsPermissions } from '@models/EnumPermissions';

@Component({
	selector: 'configurations-permissions',
	templateUrl: './permissions.component.html',
	styleUrls: ['./permissions.component.scss'],
})
export class PermissionsConfigurationsComponent implements OnInit, OnChanges {

	// @Input('reorder') Reorder: boolean = false;
	@Input('data') Data: any[] = [];

	@Input('delete') Delete: Function;

	// @ViewChild(IonReorderGroup) ReorderGroup: IonReorderGroup;

	public EnumActionsPermissions = EnumActionsPermissions;

	constructor(private NavController: NavController) { }

	ngOnInit() {

		console.log(this.Data);

	}

	ngOnChanges(changes: SimpleChanges): void {

		console.log(changes, this.Data);

	}

	// private async loadData() {


	// 	this.Data = [];


	// }

	OnChangePage(e) {

	}

	OnView(item: any) {

		this.NavController.navigateForward([EnumAppMenu.ConfigurationsPermissionView, item?.Id])
	}

	OnEdit(item: any) {

		this.NavController.navigateForward([EnumAppMenu.ConfigurationsPermissionEdit, item?.Id])
	}

	async OnDelete(item: any) {

		if (!this.Delete) return;

		const response = await this.Delete(item);

		console.log(response);

	}

	// OnReorder(ev: any | CustomEvent<any>, menus: any[]) {
	// 	// The `from` and `to` properties contain the index of the item
	// 	// when the drag started and ended, respectively
	// 	console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);

	// 	let movedItem = menus.splice(ev.detail.from, 1)[0];
	// 	menus.splice(ev.detail.to, 0, movedItem)

	// 	// // Finish the reorder and position the item in the DOM based on
	// 	// // where the gesture ended. This method can also be called directly
	// 	// // by the reorder group
	// 	ev.detail.complete();
	// 	console.log(this.Data, this.MenusList);

	// 	this.normalizeData();
	// }

	// private normalizeData() {

	// 	let count = 0;
	// 	this.Data.forEach(el => {
	// 		el.Menus.forEach(m => {
	// 			if (this.MenusList.find(i => i.Id == m.Id))
	// 				this.MenusList.find(i => i.Id == m.Id).Position = count;

	// 			++count;
	// 		})
	// 	})
	// }

	// OnReorderChild(ev: any | CustomEvent<any>) {
	// 	// The `from` and `to` properties contain the index of the item
	// 	// when the drag started and ended, respectively
	// 	console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
	// }

	// toggleReorderGroup() {
	// 	this.ReorderGroup.disabled = !this.ReorderGroup.disabled;
	// }

}
