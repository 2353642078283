<ion-input type="text" class="full outline" [disabled]="IsDisabled" [value]="FieldValue?.Code"
	(click)="OnPresentPopover($event)" readonly [placeholder]="Placeholder" />

<ion-popover #popover [isOpen]="IsOpen" (didDismiss)="IsOpen = false" class="select-search">
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-grid>
					<ion-row>
						<ion-item class="full">
							<ion-searchbar #search class="outline ion-no-margin"></ion-searchbar>
						</ion-item>
					</ion-row>
				</ion-grid>
			</ion-toolbar>
		</ion-header>
		<ion-content [scrollY]="false" class="ion-no-padding">
			<cdk-virtual-scroll-viewport class="popover ion-content-scroll-host" itemSize="48" minBufferPx="750" maxBufferPx="1360">

				<ion-grid class="ion-padding-horizontal">
					<ion-row>
						<ion-radio-group [value]="FieldValue" (ionChange)="OnChangeValue($event)">
							<ng-container
								*cdkVirtualFor="let item of Data | FilterByFields: (search?.value||''); let itemBounds = bounds; trackBy: trackByFn">

								<ion-item class="full" style="margin: 0; height: 48px; overflow: hidden;">
									<ion-radio [value]="item" slot="start"
										[disabled]="item?.ListCAE?.length==0"></ion-radio>
									<ion-label>{{ '[' + (item?.Code|| '--' ) + '] ' +
										(item?.Description||'--')}}</ion-label>
								</ion-item>

							</ng-container>
						</ion-radio-group>
					</ion-row>
					<no-items [description]="'sem resultados'"
						*ngIf="(Data | FilterByFields: (search?.value||''))?.length==0"></no-items>
				</ion-grid>
			</cdk-virtual-scroll-viewport>
		</ion-content>
	</ng-template>
</ion-popover>