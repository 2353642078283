<ion-grid class="table height-toolbar-2">

	<ion-row class="table-header">
		<ion-col class="flex-align-center" i18n=":@@configurations_permissions.name">Grupo</ion-col>
		<ion-col class="flex-align-center" i18n=":@@configurations_permissions.profile">Perfil</ion-col>
		<ion-col class="flex-align-center buttons-size-3"></ion-col>
	</ion-row>


	<ion-row *ngFor="let role of Data" style="display: block;">
		<ion-item class="ion-no-margin">

			<ion-grid class="ion-no-margin ion-no-padding">

				<ion-row class="table-body with-button-opacity">



					<ion-col class="flex-align-center">{{ role?.Name }}</ion-col>
					<ion-col class="flex-align-center">{{ role?.UserRole?.Name || '--' }}</ion-col>

					<ion-col class="flex-justify-end buttons-size-3">

						<!-- <ng-container *ngIf="!Reorder"> -->

						<ion-button fill="clear" color="dark" size="small" (click)="OnView(role)"
							[actionsPermissions]="EnumActionsPermissions.VIEW">
							<ion-icon name="eye-outline" slot="icon-only"></ion-icon>
						</ion-button>

						<ion-button fill="clear" size="small" color="dark" (click)="OnEdit(role)"
							[actionsPermissions]="EnumActionsPermissions.CREATEANDEDIT">
							<ion-icon name="create-outline" slot="icon-only"></ion-icon>
						</ion-button>

						<!-- <ion-button fill="clear" size="small" color="danger" (click)="OnDelete(role)">
										<ion-icon  name="trash-outline" slot="icon-only"></ion-icon>
									</ion-button> -->
						<!-- </ng-container> -->

					</ion-col>
				</ion-row>

			</ion-grid>

		</ion-item>

	</ion-row>


	<ion-row *ngIf="!Data || Data?.length == 0">
		<ion-col>
			<no-items></no-items>
		</ion-col>
	</ion-row>

	<!-- <pagination-control [totalItems]="Data?.length" (pageChange)="OnChangePage($event)">
	</pagination-control> -->

</ion-grid>