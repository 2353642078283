export enum EnumActionsPermissions {

    VIEW = 1,
    CREATEANDEDIT = 2,
    DELETE = 3,
    GENERATEUSERCREDENTIALS = 4,
    VIEWUSERUNDERGUARDIANSHIP = 5,
    IMPORTFILELISTMERCHANTS = 101,
    CHANGESTATUS = 102,
    VERIFYBALANCE = 103,
    REGISTCONSUMPTIONS = 104,
    IMPORTFILELISTCONSUMERS = 105,
    SYNCCATEGORY = 106,
    SYNCMERCHANT = 107,
    SYNCWALLETCONSUMER = 108,
    GENERATEFILECHARGING = 109,
    SENDFILECHARGING = 110,
    ISSUENEWCARD = 111,
    VALIDATEWALLETMOVEMENTS = 112 // municipio aprova consumo
}