import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { Storage } from '@ionic/storage-angular';

export enum EnumStorageKeys
{
    Language = "smartcardcitizen.language",
    Session = "smartcardcitizen.session",
    Preferences = "smartcardcitizen.preferences",
    Edtion = 'smartcardcitizen.edition',
    Remember = 'smartcardcitizen.remember'
}

@Injectable(
{
    providedIn: 'root'
})
export class StorageService
{
    private storage: Storage | null = null;

    private Encrypt:boolean = false;

    constructor(private Storage: Storage) 
    {

    }

    /**
     * Inicializa storage.
     */
    async Init()
    {
        this.storage = await this.Storage.create();
        this.Encrypt = environment?.EncryptStorage || false;
    }

    /**
     * Guarda em local storage um conjunto de dados para uma determinada key.
     * Caso guarde com sucesso os dados devolve os proprios dados, caso contrário devolve null.
     * @param key valor enum EnumStorageKeys
     * @param data any valor a ser guardado
     * @returns 
     */
    async Save(key:EnumStorageKeys, data:any)
    {
        try 
        {
        
            const encoded = this.Encrypt ? btoa(JSON.stringify(data)) : JSON.stringify(data);

            await this.storage?.set(key, encoded);

            return data;
        } 
        catch (error) 
        { 
            return null;
        }
    }

    /**
     * 
     * @param key 
     * @returns 
     */
    async Delete(key:EnumStorageKeys)
    {
        try 
        {
            await this.storage?.remove(key);

            return null;
        } 
        catch (error) 
        { 
            return null;
        }
    }
    
    /**
     * Efectua leitura em local storage de uma determinada key e devolve o seu valor.
     * Caso a key não existe ou seja possivel efectuar o parse de dados devolve null.
     * @param key valor enum EnumStorageKeys
     * @returns 
     */
    async Get(key:EnumStorageKeys)
    {        
        try
        {
            const value = await this.storage?.get(key);

            // return JSON.parse(value);
            return this.Encrypt ? JSON.parse(atob(value)) : JSON.parse(value);
        } 
        catch (error) 
        { 
            return null;
        }
    }
}
