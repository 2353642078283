<ion-header [translucent]="true">
    <ion-toolbar class="ion-padding-end">
        <ion-title *ngIf="Title" slot="start">{{Title}}</ion-title>
        <ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>

    </ion-toolbar>

    <ion-toolbar>
        <ion-grid class="ion-no-padding">
            <ion-row>
                <ion-col>
                    <ion-searchbar #search></ion-searchbar>
                </ion-col>
                <ion-col class="display-flex ion-justify-content-end">
                    <ion-button *ngIf="Data?.length>0" (click)="OnSaveSelection($event)">
                        Guardar
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [fullscreen]="true">

    <ion-grid class="ion-no-padding table">



        <ng-container *templateVar="(Data | searchValueProperty: search.value) as Data">

            <ion-row class="table-header">

                <ion-col class="icon-status-size-2">
                    <ion-checkbox class="ion-margin-start" *ngIf="Data?.length>0" [checked]="AllChecked"
                        [disabled]="search.value?true:false" (click)="OnChangeValue($event, null, true)"></ion-checkbox>
                </ion-col>
                <ion-col style="padding-left: 16px !important;">Comerciante</ion-col>
            </ion-row>

            <ion-row class="table-body ion-padding-horizontal" *ngFor="let item of Data">
                <ion-col class="icon-status-size-2">

                    <ion-item class="ion-no-margin">
                        <ion-checkbox [checked]="item?.Checked" [value]="item.Checked"
                            (ionChange)="OnChangeValue($event, item)"></ion-checkbox>
                    </ion-item>

                </ion-col>
                <ion-col>
                    {{item?.Name||'--'}}

                </ion-col>
            </ion-row>

            <no-items *ngIf="Data?.length==0" description="Não existem comerciantes para a categoria selecionada"></no-items>

        </ng-container>

    </ion-grid>

</ion-content>